import React from 'react';
import clsx from 'clsx';

import FieldTitle, { type FieldTitleProps } from 'components/FieldTitle';

import styles from './index.module.scss';

type NativeInputAttributes = React.InputHTMLAttributes<HTMLInputElement>;
export type FieldProps = Omit<NativeInputAttributes, 'type' | 'size' | 'title' | 'placeholder'> & {
    type: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url',
    title?: FieldTitleProps['title'],
    className?: string,
    size?: 'large' | 'medium' | 'small',
    placeholder?: string | null,
};

export default function Field({
    title,
    className,
    disabled,
    size = 'medium',
    placeholder = '',
    ...inputProps
}: FieldProps) {
    return (
        <FieldTitle title={title}>
            <input
                className={
                    clsx(
                        styles.Field,
                        size && styles[`size-${size}`],
                        disabled && styles.disabled,
                        className,
                    )
                }
                {...inputProps}
            />
        </FieldTitle>
    );
}
