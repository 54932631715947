import axios from 'axios';

const baseURL = LOCAL ? 'https://rsvp.recmanbeta.local/api/' : '/api/';

const HTTP = axios.create({ baseURL });

export function request(data: any) {
    const url = `index.php`;
    return HTTP.post(url, data);
};
