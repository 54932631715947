import React, { createContext, useContext } from 'react';
import { set, setDay, add, sub, format, getISOWeek } from 'date-fns';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import Text from 'components/Text';
import Button from 'components/Button';
import Column from 'components/Column';
import Row from 'components/Row';

import { ReactComponent as ChevronLeft } from 'assets/images/Icon-Chevron-Left-Large-Regular.svg';
import { ReactComponent as ChevronRight } from 'assets/images/Icon-Chevron-Right-Large-Regular.svg';

import styles from './index.module.scss';

export type Props = {
    shownDate: Date,
    selectedDate?: Date | null,
    className?: string,
    isDateDisabled?: (date: Date) => boolean,
    limits?: {
        min?: Date,
        max?: Date,
    },
};

export type ExternalActions = {
    setShownDate: (newDate: Date) => void,
    setSelectedDate: (newDate: Date) => void,
};

type Actions = {
    prev: () => void,
    next: () => void,
    today: () => void,
    selectDate: (newDate: Date) => void,
};

type DateRows = [
    [number, Date[]],
    [number, Date[]],
    [number, Date[]],
    [number, Date[]],
    [number, Date[]],
];

const DatepickerContext = createContext<Props & Actions>({
    shownDate: new Date(),
    selectDate: () => {},
    prev: () => {},
    next: () => {},
    today: () => {},
});

function createDateRows(shownDate: Date): DateRows {
    const firstOfTheMonth = set(shownDate, { date: 1 });
    const firstInView = setDay(firstOfTheMonth, 1, { weekStartsOn: 1 });
    const rows = [];

    for (let weeks = 0; weeks < 6; weeks += 1) {
        const firstInRow = add(firstInView, { weeks });
        const weekNumber = getISOWeek(firstInRow);
        const row = [];

        for (let days = 0; days < 7; days += 1) {
            row.push(add(firstInRow, { days }));
        }

        rows.push([weekNumber, row]);
    }

    return rows as DateRows;
}

function createWeekNumbers(dateRows: DateRows) {
    return dateRows.map(([weekNumber], i) => (
        <Column key={i}>
            <Text variant="base-primary">
                {weekNumber}
            </Text>
        </Column>
    ));
}

function createWeekdayNames() {
    const monday = setDay(new Date(), 1, { weekStartsOn: 1 });
    const weekdayNames = [];

    for (let days = 0; days < 7; days += 1) {
        const weekday = add(monday, { days });

        weekdayNames.push(
            <Column className={styles.Body__weekday} key={days}>
                <Text variant="base-elementary">
                    {format(weekday, 'cccccc')}
                </Text>
            </Column>
        );
    }

    return weekdayNames;
}

function createDates(
    dateRows: DateRows,
    shown: Date,
    select: (date: Date) => void,
    selected?: Date | null,
    isDisabled?: (date: Date) => boolean,
) {
    const today = new Date();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();
    const shownMonth = shown.getMonth();
    const selectedMonth = selected?.getMonth?.();
    const selectedDate = selected?.getDate?.();

    return dateRows.map(([_, dates], i) => (
        <Row className={styles.Body__datesRow} key={i}>
            {dates.map((date, i) => {
                const dateMonth = date.getMonth();
                const dateDate = date.getDate();
                const classNames = [];

                if (dateMonth < shownMonth) {
                    classNames.push(styles.prev);
                } else if (dateMonth > shownMonth) {
                    classNames.push(styles.next);
                }

                if (dateMonth === todayMonth && dateDate === todayDate) {
                    classNames.push(styles.today);
                }

                if (dateMonth === selectedMonth && dateDate === selectedDate) {
                    classNames.push(styles.selected);
                }

                return (
                    <button
                        type="button"
                        className={clsx(styles.Body__date, classNames)}
                        onClick={() => select(date)}
                        disabled={isDisabled && isDisabled(date)}
                        key={i}
                    >
                        <Text variant="base-elementary">
                            {date.getDate()}
                        </Text>
                    </button>
                );
            })}
        </Row>
    ));
}

function Header() {
    const { t } = useTranslation();
    const { shownDate, prev, today, next } = useContext(DatepickerContext);

    return (
        <Row className={styles.Header}>
            <Text
                variant="heading-4"
                className={styles.Header__title}
            >
                {format(shownDate, 'LLLL yyyy')}
            </Text>

            <Button 
                className={styles.Header__buttonChevron}
                onClick={prev}
                iconLeft={<ChevronLeft />}
                variant="ghost"
                size="small"
                rounded
            />

            <Button
                className={styles.Header__buttonToday}
                onClick={today}
                variant="ghost"
                size="small"
            >
                {t('today')}
            </Button>

            <Button
                className={styles.Header__buttonChevron}
                onClick={next}
                iconLeft={<ChevronRight />}
                variant="ghost"
                size="small"
                rounded
            />
        </Row>
    );
}

function Body() {
    const {
        shownDate,
        selectedDate,
        selectDate,
        isDateDisabled,
    } = useContext(DatepickerContext);
    const dateRows = createDateRows(shownDate);

    return (
        <Row className={styles.Body}>
            <Column className={styles.Body__weekNumbers}>
                <Column>
                    <Text variant="base-primary">
                        #
                    </Text>
                </Column>

                {createWeekNumbers(dateRows)}
            </Column>

            <Column className={styles.Body__dates}>
                <Row className={styles.Body__daysOfWeek}>
                    {createWeekdayNames()}
                </Row>

                <div className={styles.Body__datesGrid}>
                    {createDates(
                        dateRows,
                        shownDate,
                        selectDate,
                        selectedDate,
                        isDateDisabled,
                    )}
                </div>
            </Column>
        </Row>
    );
}

export default function Datepicker({
    setShownDate,
    setSelectedDate,
    ...restProps
}: Props & ExternalActions) {
    const next = () => {
        setShownDate(add(restProps.shownDate, { months: 1 }));
    };

    const prev = () => {
        setShownDate(sub(restProps.shownDate, { months: 1 }));
    };

    const today = () => {
        setShownDate(new Date());
    };

    const selectDate = (newDate: Date) => {
        setSelectedDate(newDate);

        if (newDate.getMonth() !== restProps.shownDate.getMonth()) {
            setShownDate(newDate);
        }
    };

    const props = {
        next,
        prev,
        today,
        selectDate,
        ...restProps,
    };

    return (
        <DatepickerContext.Provider value={props}>
            <Column className={clsx(styles.Datepicker, props.className)}>
                <Header />
                <Body />
            </Column>
        </DatepickerContext.Provider>
    );
}
