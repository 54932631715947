export const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const MAX_BOOKING_ADVANCE = { days: 60 };
export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';

export const enum BookYourselfStep {
    PickDateAndTime = 1,
    EnterContactDetails = 2,
    BookingConfirmed = 3,
}

export const enum RecurrenceType {
    NoRecurrence = 0,
    Weekly = 1,
    Custom = 2,
};

export const enum ConferencingType {
    None = 0,
    GoogleMeet = 1,
    MicrosoftTeams = 2,
};

export type Interval<T> = {
    start: T,
    end: T,
};

export type Schedule = {
    title: string,
    organizer: {
        name: string,
        img?: string,
        email: string,
        corportation?: {
            name: string,
        },
    },
    timeZone: string,
    recurrenceType: RecurrenceType,
    recurrenceRepeatEvery: number,
    logo?: string,
    eventDuration: number,
    conferencingType: ConferencingType,
    bufferTime: number,
    availability: Record<string, Interval<string>[]>,
    notes?: string,
    start?: string,
    end?: string,
    availableInAdvance?: number,
    bookTimeBefore?: number,
};
