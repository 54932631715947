/**
 * Modified copy of /web-calendar/helpers/appointmentSchedule/convertToEvents.ts
 * Any fixes for this code should be considered for web calendar as well to keep slots
 * similar on both projects
 */

import { setDay, startOfWeek, startOfDay, differenceInWeeks } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

import { type Schedule, type Interval, RecurrenceType } from 'constants/schedule';
import { HOUR, MINUTE, WEEK } from 'constants/time';

import getISOWeekdayFromString from '../getISOWeekdayFromString';
import isIntersecting from '../isIntersecting';

function generateTimeSlots(
    schedule: Schedule,
    intervalStart: number,
    intervalEnd: number,
    busyAt: Interval<number>[],
) {
    const breakDuration = (schedule.bufferTime || 0) * MINUTE;
    const eventDuration = schedule.eventDuration * MINUTE;
    const timeSlots: Interval<number>[] = [];

    let nextStart = intervalStart;
    while (nextStart < intervalEnd) {
        const timeSlotStart = nextStart;
        const timeSlotEnd = timeSlotStart + eventDuration;

        if (timeSlotEnd > intervalEnd) {
            break;
        }

        const intersectingWith = busyAt.find((interval: Interval<number>) =>
            isIntersecting(interval.start * 1000, interval.end * 1000, timeSlotStart, timeSlotEnd),
        );

        if (intersectingWith) {
            nextStart = intersectingWith.end * 1000 + breakDuration;
        } else {
            timeSlots.push({
                start: timeSlotStart,
                end: timeSlotEnd,
            });

            nextStart = timeSlotEnd + breakDuration;
        }
    }

    return timeSlots;
}

// should return 00:00:00 of days in time zone of schedule
function findWeekdays(
    weekdayStr: string,
    periodStart: number,
    periodEnd: number,
    scheduleTimeZone: string,
) {
    const isoWeekday = getISOWeekdayFromString(weekdayStr);

    if (!isoWeekday) {
        return [];
    }

    const periodStartLocal = new Date(periodStart);
    const firstWeekdayLocal = setDay(periodStartLocal, isoWeekday, { weekStartsOn: 1 });
    const firstWeekdayZoned = toZonedTime(firstWeekdayLocal, scheduleTimeZone).getTime();
    const weekdays = [];

    let weekday = firstWeekdayZoned;
    while (weekday <= periodEnd) {
        weekdays.push(weekday);
        weekday += WEEK;
    }

    return weekdays;
}

// expected time format: HH:mm
function addTimeFromString(time: string, target: number) {
    const [hoursString, minutesString] = time.split(':');
    const hours = +hoursString;
    const minutes = +minutesString;

    if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        return target;
    }

    return target + hours * HOUR + minutes * MINUTE;
}

export default function convertToIntervals(
    schedule: Schedule,
    periodStart: number,
    periodEnd: number,
    busyAt: Interval<number>[],
): Interval<number>[] {
    const now = Date.now();
    const currentWeekStart = startOfWeek(now).getTime();
    const todayStart = startOfDay(now).getTime();

    if (periodEnd < currentWeekStart) {
        return [];
    }

    const scheduleStart =
        schedule.start &&
        fromZonedTime(schedule.start, schedule.timeZone).getTime();
    const scheduleEnd =
        schedule.end &&
        fromZonedTime(schedule.end, schedule.timeZone).getTime();
    const repeatEvery = schedule.recurrenceRepeatEvery || 1;

    return Object.entries(schedule.availability)
        .flatMap(([dateStr, intervals]) => {
            if (!intervals?.length) {
                return [];
            }

            const shownOnDates = schedule.recurrenceType === RecurrenceType.NoRecurrence
                ? [fromZonedTime(dateStr, schedule.timeZone).getTime()]
                : findWeekdays(
                      dateStr,
                      periodStart,
                      periodEnd,
                      schedule.timeZone,
                  );

            return shownOnDates.flatMap((date: number) => {
                if (Number.isNaN(date)) {
                    return [];
                }

                if (scheduleStart && date < scheduleStart) {
                    return [];
                }

                if (scheduleEnd && date > scheduleEnd) {
                    return [];
                }

                if (scheduleStart && differenceInWeeks(date, scheduleStart) % repeatEvery !== 0) {
                    return [];
                }

                if (date < todayStart) {
                    return [];
                }

                return intervals.flatMap((interval) => {
                    const start = addTimeFromString(interval.start, date);
                    const end = addTimeFromString(interval.end, date);

                    if (end <= Date.now()) {
                        return [];
                    }

                    return generateTimeSlots(
                        schedule,
                        start,
                        end,
                        busyAt,
                    );
                });
            });
        })
        .filter((option) => option.start >= now);
}
