const isoWeekdays: Record<string, number> = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
};

export default function getISOWeekdayFromString(weekday: string): number | null {
    return isoWeekdays[weekday] ?? null;
}
