export default function getScheduleToken() {
    const scheduleToken = new URLSearchParams(window.location.search)
        .get('key');

    if (!scheduleToken) {
        // TODO 404;
        return null;
    }

    return scheduleToken;
}

