import { type ErrorType } from 'constants/models';

import notFound from './notFound';
import captcha from './captcha';

type NecessaryResponseData = {
    data: {
        success: boolean | 1 | 0,
        errors?: ErrorType[],
    },
};

export default function redirectIfNotSuccesful(response: NecessaryResponseData) {
    if (!response.data.success && response.data.errors) {
        if (response.data.errors.some((obj: ErrorType) => obj.code === 5)) {
            captcha();
        }

        if (response.data.errors.some((obj: ErrorType) => obj.code === 7)) {
            notFound();
        }

        // TODO add another general redirect if unknown errors present

        return;
    }
}
