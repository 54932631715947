import React, {
    type CSSProperties,
    type ReactNode,
} from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

type ColumnProps = CSSProperties & {
    className?: string,
    children?: ReactNode,
};

export default function Column({
    className,
    children,
    ...cssProps
}: ColumnProps) {
    return (
        <div
            className={clsx(styles.Column, className)}
            style={cssProps}
        >
            {children}
        </div>
    );
}
