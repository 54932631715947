import React from 'react';

import Skeleton from 'components/Skeleton';
import Column from 'components/Column';
import Row from 'components/Row';

import styles from '../index.module.scss';

export default function() {
    return (
        <Column className={styles.BookYourself_ScheduleInfo}>
            <Skeleton
                width="150px"
                height="16px"
                marginBottom="4px"
            />

            <Row>
                <Skeleton
                    width="24px"
                    height="24px"
                    variant="circle"
                />

                <Skeleton
                    width="150px"
                    height="16px"
                    margin="4px 0px 4px 6px"
                />
            </Row>

            <Row>
                <Skeleton
                    width="16px"
                    height="16px"
                    variant="circle"
                />

                <Skeleton
                    width="90px"
                    height="16px"
                    margin="0px 0px 0px 8px"
                />
            </Row>

            <Row>
                <Skeleton
                    width="16px"
                    height="16px"
                    variant="circle"
                />

                <Skeleton
                    width="152px"
                    height="16px"
                    margin="0px 0px 0px 8px"
                />
            </Row>

            <Row>
                <Skeleton
                    width="16px"
                    height="16px"
                    variant="circle"
                />

                <Skeleton
                    width="128px"
                    height="16px"
                    margin="0px 0px 0px 8px"
                />
            </Row>
        </Column>
    );
}
