const tasks = new Set()

type Task = (...args: any) => void;

function runTask<T extends Task>(
    task: T,
    args?: Parameters<T>,
) {
    return () => {
        tasks.delete(task);
        task(...(args || []));
    }
}

export default function runOutsideStack<T extends Task>(
    task: T,
    args?: Parameters<T>,
) {
    if (!tasks.has(task)) {
        tasks.add(task);
        setTimeout(runTask(task, args), 0);
    }
}
