import { useState, useRef } from 'react';

import runOutsideStack from './runOutsideStack';

/**
 * Quick and tiny store made initially for BookYourself,
 * example usage can be found there.
 *
 * If you looking here to create a store for anything else
 * then its probably time to use third party solution.
 */

export default function createStore<State extends Object>(
    defaultState: State,
) {
    type UpdaterFunc = (state: State) => void;
    type MutatorFunc = (state: State) => State | void;
    type SelectorFunc = (state: State) => any;

    let updateQueue: UpdaterFunc[] = [];
    let state: State = defaultState;

    function runUpdater(
        queue: UpdaterFunc[],
        state: State,
    ) {
        queue.forEach((updater) => updater(state));
    }

    function mutateStore(mutator: MutatorFunc) {
        const currentUpdateQueue = updateQueue;

        state = mutator(state) || state;
        updateQueue = [];

        runOutsideStack(runUpdater, [currentUpdateQueue, state]);
    }

    function useStore<Selector extends SelectorFunc>(
        selector: Selector,
    ): ReturnType<Selector> {
        const [value, setNewValue] = useState(selector(state));
        const setNewValueRef = useRef<typeof setNewValue>();
        const updaterRef = useRef<UpdaterFunc>();
        const prevValueRef = useRef(value);

        setNewValueRef.current = setNewValue;

        if (!updaterRef.current) {
            updaterRef.current = (newState: State) => {
                const newValue = selector(newState);

                if (newValue === prevValueRef.current && updaterRef.current) {
                    updateQueue.push(updaterRef.current);
                    return;
                }

                if (setNewValueRef.current) {
                    prevValueRef.current = newValue;
                    setNewValueRef.current(newValue);
                }

                delete updaterRef.current;
            };

            updateQueue.push(updaterRef.current);
        }

        return value;
    }

    function getState() {
        return state;
    }

    return [
        useStore,
        mutateStore,
        getState,
    ] as [
        typeof useStore,
        typeof mutateStore,
        typeof getState,
    ];
}
