import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/Text';
// import Button from 'components/Button';
import Column from 'components/Column';
import Row from 'components/Row';

import { ReactComponent as CheckIcon }
    from 'assets/images/Icon-Check-Circle-Regular.svg';
// import { ReactComponent as ShareIcon }
//     from 'assets/images/Icon-External-Regular.svg';

import styles from '../index.module.scss';
import useBookYourself, { emailSelector } from '../store';

export default function BookingConfirmed() {
    const { t } = useTranslation();
    const email = useBookYourself(emailSelector);

    return (
        <Column className={styles.BookYourself_BookingConfirmed}>
            <Row className={styles.BookYourself_BookingConfirmed__title}>
                <CheckIcon />

                <Text variant="heading-1">
                    {t('bookingConfirmed')}
                </Text>
            </Row>

            <Row className={styles.BookYourself_BookingConfirmed__explanation}>
                <Text variant="base-primary">
                    {t('aCalendarInvitationHasBeenSentTo')}

                    <a href={`mailto:${email}`}>
                        {email}
                    </a>
                </Text>
            </Row>

            {/*
            <Button
                variant="secondary"
                size="medium"
                iconLeft={<ShareIcon />}
            >
                {t('openInvitation')}
            </Button>
            */}
        </Column>
    );
}
