import dayjs from "utils/dayjs";
import type { Dayjs } from "dayjs";

import { sortWeekdays } from "helpers/date/sortWeekdays";
import { isWeekdaysInConsecutiveOrder } from "helpers/date/isWeekdaysInConsecutiveOrder";

/**
 * Locale keys:
 * customRecurrenceOnce
 * customRecurrenceEvery
 * customRecurrenceUnit1
 * customRecurrenceUnit2
 * customRecurrenceUnit3
 * customRecurrenceUnit4
 * customRecurrenceUnit5
 * customRecurrenceUnit1plural
 * customRecurrenceUnit2plural
 * customRecurrenceUnit3plural
 * customRecurrenceUnit4plural
 * customRecurrenceUnit5plural
 * customRecurrenceOnAllDays
 * customRecurrenceOnWeekdaysInConsecutiveOrder
 * customRecurrenceOnWeekdays
 * customRecurrenceOnDateEachMonth
 * customRecurrenceOnWeekdayEachMonth
 * customRecurrenceUntil
 * customRecurrenceXTimes
 * count2
 * count3
 * count4
 */

type Weekday = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
type UnixTimestamp = number;

enum MonthType {
  ON_DATE = 1,
  ON_WEEKDAY = 2,
}

enum Recurrence {
  SINGLE = 0,
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  WORKDAY = 4,
  YEAR = 5,
}

type RecurrenceOptions = {
  repeatEvery: number;
  days?: Weekday[];
  repetitions?: number;
  endDate?: UnixTimestamp;
  monthType?: MonthType;
};

type RecurrenceDescripionGenerator = (
  recurrence: Recurrence,
  recurrenceOptions: RecurrenceOptions,
  eventStartDate: Dayjs,
  t: (
    key: string,
    defaultValue?: string,
    variables?: Record<string, any>
  ) => string,
) => string | null;

const repeatsOrNot: RecurrenceDescripionGenerator = (
  recurrence,
  recurrenceOptions,
  _,
  t,
) => {
  // Once
  if (recurrenceOptions.repetitions === 1) {
    return t("customRecurrenceOnce", "Once");
  }

  // Every ?(x of) smth
  const { repeatEvery } = recurrenceOptions;

  if (repeatEvery === 1) {
    const timeUnitName = t(`customRecurrenceUnit${recurrence}`);
    return t("customRecurrenceEvery", "Every {{description}}", {
      description: timeUnitName,
    });
  }

  const timeUnitName = t(
    `customRecurrenceUnit${recurrence}plural`,
    `[unit_${recurrence}]s` // Should be depending on recurrence value
  );
  return t("customRecurrenceEvery", "Every {{description}}", {
    description: `${repeatEvery} ${timeUnitName}`,
  });
};

const onWhatDays: RecurrenceDescripionGenerator = (
  recurrence,
  recurrenceOptions,
  eventStartDate,
  t,
) => {
  const { days, monthType } = recurrenceOptions;

  // on Tue - Fri
  if (recurrence === Recurrence.WEEK && days?.length) {
    if (days.length === 7) {
      return t("customRecurrenceOnAllDays", "on all days");
    }

    const daysSorted = sortWeekdays(days);
    const isInOrder = isWeekdaysInConsecutiveOrder(daysSorted);

    if (isInOrder) {
      const first = daysSorted.at(0);
      const last = daysSorted.at(-1);

      return t(
        "customRecurrenceOnWeekdaysInConsecutiveOrder",
        "on {{first}} - {{last}}",
        { first, last }
      );
    }

    const daysLocalized = daysSorted.map((day) => t(day.toLowerCase(), day));

    return t("customRecurrenceOnWeekdays", "on {{weekdays}}", {
      weekdays: daysLocalized.join(", "),
    });
  }

  // on ?? each month
  if (recurrence === Recurrence.MONTH) {
    if (monthType === MonthType.ON_DATE) {
      const date = dayjs(eventStartDate).format("Do");

      return t(
        "customRecurrenceOnDateEachMonth",
        "on day {{date}} each month",
        { date }
      );
    }

    if (monthType === MonthType.ON_WEEKDAY) {
      const weekday = dayjs(eventStartDate).format("dddd");

      const weekdayNumber = getNumberOfDayOfWeek(eventStartDate);

      const weekdayCount = t(`count${weekdayNumber}`, `[${weekdayNumber}]`);

      return t(
        "customRecurrenceOnWeekdayEachMonth",
        "on every {{weekdayCount}} {{weekday}}",
        { weekdayCount, weekday }
      );
    }
  }

  return null;
};

const getNumberOfDayOfWeek = (eventStartDate: Dayjs) => {
  let countDayOfWeek = 1;
  let startDate = eventStartDate.date();

  while (startDate - 7 > 0) {
    startDate -= 7;
    countDayOfWeek++;
  }

  return countDayOfWeek;
};

const untilWhat: RecurrenceDescripionGenerator = (
  _,
  recurrenceOptions,
  __,
  t,
) => {
  const { endDate, repetitions } = recurrenceOptions;

  if (endDate) {
    const end = dayjs(endDate * 1000).format("Do MMMM YYYY");

    return t("customRecurrenceUntil", "until {{end}}", { end });
  }

  if (repetitions && repetitions > 1) {
    return t("customRecurrenceXTimes", "{{repetitions}} times", {
      repetitions,
    });
  }

  return null;
};

const descriptionGenerators: RecurrenceDescripionGenerator[] = [
  repeatsOrNot,
  onWhatDays,
  untilWhat,
];

export const getCustomRecurrenceString: RecurrenceDescripionGenerator = (
  recurrence,
  recurrenceOptions,
  eventStartDate,
  t,
): string => {
  return descriptionGenerators
    .map((f) => f(recurrence, recurrenceOptions, eventStartDate, t))
    .filter(Boolean)
    .join(", ");
};
