import React, { type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/Text';
import Button from 'components/Button';
import FieldText from 'components/FieldText';
import Column from 'components/Column';
import Row from 'components/Row';

import { BookYourselfStep } from 'constants/schedule';

import styles from '../index.module.scss';
import useBookYourself, {
    goToStep,
    setBookingData,
    firstNameSelector,
    lastNameSelector,
    emailSelector,
    addedNotesSelector,
    confirmBooking,
} from '../store';

function back() {
    goToStep(BookYourselfStep.PickDateAndTime);
}

function FirstName() {
    const { t } = useTranslation();
    const firstName = useBookYourself(firstNameSelector);

    const updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setBookingData({ firstName: event.target.value });
        }
    }

    return (
        <FieldText
            title={t('firstName')}
            placeholder={t('enterFirstName')}
            onChange={updateFirstName}
            value={firstName}
            required
        />
    );
}

function LastName() {
    const { t } = useTranslation();
    const lastName = useBookYourself(lastNameSelector);

    const updateLastName = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setBookingData({ lastName: event.target.value });
        }
    }

    return (
        <FieldText
            title={t('lastName')}
            placeholder={t('enterLastName')}
            onChange={updateLastName}
            value={lastName}
        />
    );
}

function Email() {
    const { t } = useTranslation();
    const email = useBookYourself(emailSelector);

    const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setBookingData({ email: event.target.value });
        }
    }

    return (
        <FieldText
            title={t('email')}
            placeholder={t('enterEmail')}
            onChange={updateEmail}
            value={email}
            type="email"
            required
        />
    );
}

function Notes() {
    const { t } = useTranslation();
    const notes = useBookYourself(addedNotesSelector);

    const updateNotes = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setBookingData({ notes: event.target.value });
        }
    }

    return (
        <FieldText
            title={t('notes')}
            placeholder={t('addNotes')}
            onChange={updateNotes}
            value={notes}
        />
    );
}

export default function EnterContactDetails() {
    const { t } = useTranslation();

    return (
        <Column className={styles.BookYourself_EnterContactDetails}>
            <Column className={styles.BookYourself_EnterContactDetails__header}>
                <Text
                    variant="heading-3"
                    className={styles.textString}
                >
                    {t('enterYourContactDetails')}
                </Text>

                <Text
                    variant="base-elementary"
                    className={styles.textInfo}
                >
                    {t('wellSendYouAnEmail')}
                </Text>
            </Column>

            <Column className={styles.BookYourself_EnterContactDetails__body}>
                <FirstName />
                <LastName />
                <Email />
                <Notes />
            </Column>

            <Row className={styles.BookYourself_EnterContactDetails__footer}>
                <Button
                    variant="secondary"
                    size="large"
                    onClick={back}
                >
                    {t('back')}
                </Button>

                <Button
                    variant="primary"
                    size="large"
                    onClick={confirmBooking}
                >
                    {t('confirmBooking')}
                </Button>
            </Row>
        </Column>
    );
}
