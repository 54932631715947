import {
    useNavigate,
    useSearchParams,
    type To,
    type NavigateOptions,
    type NavigateFunction,
} from 'react-router-dom';

let currentNavigate: NavigateFunction | null = null;
let currentSearch: URLSearchParams | null = null;

export function catchNavigate() {
    const [search] = useSearchParams();

    currentNavigate = useNavigate();
    currentSearch = search;
}

export function getFromSearch(key: string) {
    if (!currentSearch) {
        return null;
    }

    return currentSearch.get(key);
}

export default function navigate(to: To, options?: NavigateOptions) {
    if (currentNavigate) {
        currentNavigate(to, options);
    }
}
