import React from 'react';

import Field, { type FieldProps } from 'components/Field';

export type FieldTextProps = Omit<FieldProps, 'type'> & {
    type?: FieldProps['type'],
};

export default function FieldText({
    type = 'text',
    ...fieldProps
}: FieldTextProps) {
    return (
        <Field
            type={type}
            {...fieldProps}
        />
    );
}
