import React from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

type DividerProps = {
    direction: 'h' | 'v',
    className?: string,
};

export default function Divider({
    className,
    direction = 'h',
}: DividerProps) {
    return (
        <div
            className={
                clsx(
                    styles.Divider,
                    styles[direction],
                    className,
                )
            }
        />
    );
}
