import navigate, { getFromSearch } from 'helpers/navigate';

export default function captcha() {
    const mode = getFromSearch('mode');
    const key = getFromSearch('key');

    navigate(
        `/recaptcha?mode=${mode}&key=${key}`,
        {
            state: {
                mode,
                key
            },
        },
    );
}
