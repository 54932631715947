import { setDay, set, add } from 'date-fns';

import { FIRST_OF_THE_MONTH, DAYS_IN_MONTH_VIEW } from 'constants/datepicker';

const MONDAY = 1;

export default function getDatepickerViewBoundaries(dateInsideMonth: Date) {
    const firstOfTheMonth = set(dateInsideMonth, FIRST_OF_THE_MONTH);
    const start = setDay(firstOfTheMonth, MONDAY, { weekStartsOn: 1 });
    const end = add(start, { days: DAYS_IN_MONTH_VIEW });

    return [start, end];
}
