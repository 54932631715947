import React, { type ReactNode } from 'react';
import { formatDuration, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Text from 'components/Text';
import Column from 'components/Column';
import Row from 'components/Row';

import { BookYourselfStep, ConferencingType, TIME_FORMAT } from 'constants/schedule';

import splitMinutes from 'helpers/date/splitMinutes';

import { ReactComponent as SelectedTimeslotIcon }
    from 'assets/images/Icon-Check-Large-Regular.svg';
import { ReactComponent as DurationIcon }
    from 'assets/images/Icon-Progres50-Regular.svg';
import { ReactComponent as GoogleMeetLogo }
    from 'assets/images/Icon-GoogleMeet-Color.svg';
import { ReactComponent as MicrosoftTeamsLogo }
    from 'assets/images/Icon-MicrosoftTeams-Color.svg';
import { ReactComponent as TimezoneIcon }
    from 'assets/images/Icon-World-Regular.svg';
import { ReactComponent as NotesIcon }
    from 'assets/images/Icon-Name-Regular.svg';

import styles from '../index.module.scss';
import useBookYourself, {
    scheduleIsLoadingSelector,
    titleSelector,
    organizerNameSelector,
    organizerAvatarSelector,
    eventDurationSelector,
    timezoneSelector,
    notesSelector,
    conferenceSelector,
    currentStepSelector,
    selectedOptionSelector,
} from '../store';

import Skeleton from './skeleton';

type PropertyProps = {
    icon: ReactNode,
    children: ReactNode,
    className?: string,
};

function Property({
    icon,
    children,
    className,
}: PropertyProps) {
    return (
        <Row
            className={
                clsx(
                    styles.BookYourself_ScheduleInfo_Property,
                    className,
                )
            }
        >
            <div className={styles.BookYourself_ScheduleInfo_Property__icon}>
                {icon}
            </div>

            <Column
                className={styles.BookYourself_ScheduleInfo_Property__body}
            >
                {children}
            </Column>
        </Row>
    );
}

function Organizer() {
    const name = useBookYourself(organizerNameSelector);
    const avatar = useBookYourself(organizerAvatarSelector);

    const avatarElement = avatar
        ? <img src={avatar} />
        : undefined; // TODO default image

    return (
        <Property
            icon={avatarElement}
            className={styles.BookYourself_ScheduleInfo_Property_Organizer}
        >
            <Text
                variant="base-primary"
                className={styles.textStrong}
                truncate={false}
            >
                {name}
            </Text>
        </Property>
    );
}

function SelectedTimeslot() {
    const currentStep = useBookYourself(currentStepSelector);
    const selectedOption = useBookYourself(selectedOptionSelector);

    if (currentStep < BookYourselfStep.EnterContactDetails) {
        return null;
    }

    return (
        <Property
            icon={<SelectedTimeslotIcon />}
        >
            <Text
                variant="base-primary"
                className={styles.textStrong}
                truncate={false}
            >
                {format(selectedOption.start, 'EEE, MMMM d')}
            </Text>

            <Text
                variant="base-elementary"
                className={styles.textInfo}
                truncate={false}
            >
                {format(selectedOption.start, TIME_FORMAT)}
                {' - '}
                {format(selectedOption.end, TIME_FORMAT)}
            </Text>
        </Property>
    );
}

function Duration() {
    const minutes = useBookYourself(eventDurationSelector);
    const durationString = formatDuration(splitMinutes(minutes));

    /* TODO check if date-fns is configured to support current lang */

    return (
        <Property
            icon={<DurationIcon />}
        >
            <Text
                variant="base-primary"
                className={styles.textStrong}
                truncate={false}
            >
                {durationString}
            </Text>
        </Property>
    );
}

function Conference() {
    const conferencingType = useBookYourself(conferenceSelector);
    const { t } = useTranslation();

    if (conferencingType === ConferencingType.None) {
        return null;
    }

    const conferencingServiceName = conferencingType === ConferencingType.GoogleMeet
        ? 'Google Meet'
        : 'Microsoft Teams';

    const conferencingServiceLogo = conferencingType === ConferencingType.GoogleMeet
        ? <GoogleMeetLogo />
        : <MicrosoftTeamsLogo />;

    return (
        <Property
            icon={conferencingServiceLogo}
        >
            <Text
                variant="base-primary"
                className={styles.textStrong}
                truncate={false}
            >
                {conferencingServiceName}
            </Text>

            <Text
                variant="base-elementary"
                className={styles.textInfo}
                truncate={false}
            >
                {t('availableUponEventConfirmation')}
            </Text>
        </Property>
    );
}

function Timezone() {
    const timezone = useBookYourself(timezoneSelector);

    return (
        <Property
            icon={<TimezoneIcon />}
        >
            <Text
                variant="base-primary"
                className={styles.textStrong}
                truncate={false}
            >
                {timezone}
            </Text>
        </Property>
    );
}

function Notes() {
    const notes = useBookYourself(notesSelector);

    if (!notes) {
        return null;
    }

    // TODO test inner html safety
    return (
        <Property
            icon={<NotesIcon />}
        >
            {notes}
        </Property>
    );
}

export default function ScheduleInfo() {
    const title = useBookYourself(titleSelector);
    const isLoading = useBookYourself(scheduleIsLoadingSelector);

    if (isLoading) {
        return (
            <Skeleton />
        );
    }

    return (
        <Column className={styles.BookYourself_ScheduleInfo}>
            <Text
                variant="heading-3"
                className={styles.textStrong}
                truncate={false}
            >
                {title}
            </Text>

            <Column className={styles.BookYourself_ScheduleInfo__body}>
                <Organizer />
                <SelectedTimeslot />
                <Duration />
                <Conference />
                <Timezone />
                <Notes />
            </Column>
        </Column>
    );
}
