import React, { type ReactNode } from 'react';

import Text from 'components/Text';
import Column from 'components/Column';

import styles from './index.module.scss';

export type FieldTitleProps = {
    title: string | null | undefined,
    children: ReactNode,
};

export default function FieldTitle({
    title,
    children,
}: FieldTitleProps) {
    if (!title) {
        return children as JSX.Element;
    }

    return (
        <Column className={styles.FieldTitle}>
            <Text
                variant="base-elementary"
                truncate={false}
            >
                {title}
            </Text>

            {children}
        </Column>
    );
}
