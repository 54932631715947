import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BookYourself from 'pages/BookYourself';
import PageNotFound from 'pages/PageNotFound';
import Recaptcha from 'pages/Recaptcha';
import Main from 'pages/Main';

import { catchNavigate, getFromSearch } from 'helpers/navigate';
import notFound from 'helpers/redirects/notFound';

import { PATHS, MODES } from 'constants/constants';

function EventPage() {
    const mode = getFromSearch('mode');

    switch (mode) {
        case MODES.bookYourself:
            return <BookYourself />;

        case MODES.event:
        case MODES.invite:
            return <Main />;

        default:
            notFound();
            return null;
    }
}

export default function App() {
    catchNavigate();

    return (
        <Routes>
            <Route path={PATHS.index} element={<EventPage />} />
            <Route path={PATHS.recaptcha} element={<Recaptcha />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};
