import React, { useRef, useEffect, useState } from "react";

import Skeleton from 'components/Skeleton';
import DropDown from "components/DropDown";
import Tooltip from "components/Tooltip";

import { ReactComponent as DefaultLogo } from "assets/images/logo.svg";

import styles from "./index.module.scss";

type LogoProps = {
    src?: string,
    loading?: boolean,
};

function Logo({
    src,
    loading = false,
}: LogoProps) {
    if (loading) {
        return <Skeleton width="65px" height="32px" />;
    }

    if (!src) {
        <DefaultLogo className={styles.header__logo} />
    }

    return <img className={styles.header__logo} src={src} alt="logo" />;
}

interface HeaderProps {
  logo?: string;
  title?: string;
  loading?: boolean;
}

const Header = ({ logo, title, loading = false }: HeaderProps) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (titleRef) {
      const titleWrapperElement = titleRef.current;

      const handleTooltip = () => {
        if (titleWrapperElement) {
          setTimeout(() => {
            setIsTooltipVisible(
              titleWrapperElement.scrollWidth > titleWrapperElement.clientWidth
            );
          }, 100);
        }
      };
      handleTooltip();

      window.addEventListener("resize", handleTooltip);

      return () => {
        window.removeEventListener("resize", handleTooltip);
      };
    }
  }, [titleRef.current]);

  return (
    <header className={styles.header}>
      <div className={styles.header__inner}>
        <Logo src={logo} loading={loading} />

        <h2 className={styles.header__title} ref={titleRef}>
          {title}
        </h2>
        {isTooltipVisible && title && (
          <Tooltip text={title} classname={styles.tooltip} />
        )}

        <div className={styles.dropdown}>
          <DropDown />
        </div>
      </div>
    </header>
  );
};

export default Header;
