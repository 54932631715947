import React, {} from 'react';

import Skeleton from 'components/Skeleton';
import Column from 'components/Column';
import Row from 'components/Row';

import styles from '../index.module.scss';

export default function() {
    return (
        <>
            <Column className={styles.BookYourself_PickDateAndTime__date}>
                <Skeleton
                    width="200px"
                    height="24px"
                />

                <Skeleton
                    width="320px"
                    height="320px"
                />
            </Column>

            <Column className={styles.BookYourself_PickDateAndTime__time}>
                <Row
                    className={
                        styles.BookYourself_PickDateAndTime__time__selectedDate
                    }
                >
                    <Skeleton
                        width="130px"
                        height="24px"
                    />
                </Row>

                <Column
                    className={
                        styles.BookYourself_PickDateAndTime__time__options
                    }
                >
                    <Skeleton
                        width="100%"
                        height="44px"
                        marginBottom="8px"
                    />

                    <Skeleton
                        width="100%"
                        height="44px"
                        marginBottom="8px"
                    />

                    <Skeleton
                        width="100%"
                        height="44px"
                        marginBottom="8px"
                    />

                    <Skeleton
                        width="100%"
                        height="44px"
                        marginBottom="8px"
                    />

                    <Skeleton
                        width="100%"
                        height="44px"
                        marginBottom="8px"
                    />
                </Column>
            </Column>
        </>
    );
}
