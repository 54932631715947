import { getUnixTime } from 'date-fns';

import createStore from 'helpers/createStore';

import { SCOPES } from 'constants/constants';
import {
    BookYourselfStep,
    RecurrenceType,
    ConferencingType,
    type Schedule,
    type Interval,
} from 'constants/schedule';

import redirectIfNotSuccesful from 'helpers/redirects/redirectIfNotSuccesful';
import getDatepickerViewBoundaries from 'helpers/datepicker/getDatepickerViewBoundaries';
import getScheduleToken from 'helpers/schedule/getScheduleToken';
import isValidSchedule from 'helpers/schedule/isValidSchedule';

import { request } from 'api/APIService';
import convertToOptions from 'helpers/schedule/convertToOptions';

type ValidationError = {
    origin: string,
    message: string,
};

export type BookYourselfState = {
    currentStep: BookYourselfStep,
    schedule: Schedule & {
        isLoading: boolean,
    },
    datepicker: {
        shownDate: Date,
        selectedDate: Date | null,
    },
    availability: {
        isLoading: boolean,
        options: Interval<number>[],
    },
    booking: {
        selectedOption: Interval<number>,
        firstName: string,
        lastName: string,
        email: string,
        notes: string,
        errors: ValidationError[],
    },
};

const defaultState = (): BookYourselfState => ({
    currentStep: BookYourselfStep.PickDateAndTime,
    schedule: {
        isLoading: true,
        title: '',
        organizer: {
            name: '',
            email: '',
        },
        timeZone: 'Europe/Oslo',
        recurrenceType: RecurrenceType.NoRecurrence,
        recurrenceRepeatEvery: 0,
        eventDuration: 0,
        conferencingType: ConferencingType.None,
        bufferTime: 0,
        availability: {},
    },
    datepicker: {
        shownDate: new Date(),
        selectedDate: null,
    },
    availability: {
        isLoading: true,
        options: [],
    },
    booking: {
        selectedOption: {
            start: 0,
            end: 0,
        },
        firstName: '',
        lastName: '',
        email: '',
        notes: '',
        errors: [],
    },
});

const [useBookYourself, mutate, getState] = createStore(defaultState());
export default useBookYourself;
export { getState };

/* Mutators */
function setSchedule(newSchedule: Schedule) {
    mutate((state) => {
        state.schedule = {
            ...defaultState().schedule,
            ...newSchedule,
            isLoading: false,
        };
    });
}

export async function fetchSchedule() {
    // TODO try?
    const scheduleToken = getScheduleToken();

    if (!scheduleToken) {
        return;
    }

    const response = await request({
        scope: SCOPES.fetchSchedule,
        scheduleToken,
    });

    if (!response.data.success) {
        redirectIfNotSuccesful(response);
    }

    if (isValidSchedule(response.data.data)) {
        setSchedule(response.data.data);

        const today = new Date();
        setShownDate(today);
    }

    // TODO generalized handle
}

function isValidBusyInterval(data: any): data is Interval<number> {
    return (
        !!data
        && data.start.constructor === Number
        && data.end.constructor === Number
    );
}

async function fetchAvailability(
    start: Date,
    end: Date,
) {
    const scheduleToken = getScheduleToken();

    // TODO race conditions

    if (!scheduleToken) {
        return;
    }

    const response = await request({
        scope: SCOPES.fetchScheduleAvailability,
        start: getUnixTime(start),
        end: getUnixTime(end),
        scheduleToken,
    });

    if (
        !!response.data.success
        && response.data.data.every(isValidBusyInterval)
    ) {
        return response.data.data;
    }

    return [];
}

async function bookEvent(
    start: number,
    end: number,
    firstName: string,
    lastName: string,
    email: string,
) {
    // TODO can we send notes?
    const scheduleToken = getScheduleToken();

    const response = await request({
        scope: SCOPES.bookScheduleEvent,
        start: getUnixTime(start),
        end: getUnixTime(end),
        firstName,
        lastName,
        email,
        scheduleToken,
    });

    return response.data?.success || false;
}

export function selectOption(option: Interval<number>) {
    mutate((state) => {
        state.booking.selectedOption = option;
        state.currentStep = BookYourselfStep.EnterContactDetails;
    });
}

export async function setShownDate(newDate: Date) {
    mutate((state) => {
        state.availability.isLoading = true;
    });

    const [viewStart, viewEnd] = getDatepickerViewBoundaries(newDate);
    const busyAt = await fetchAvailability(viewStart, viewEnd);

    mutate((state) => {
        state.datepicker.shownDate = newDate;

        state.availability.isLoading = false;
        state.availability.options = convertToOptions(
            state.schedule,
            viewStart.getTime(),
            viewEnd.getTime(),
            busyAt,
        ) || [];
    });
}

export async function setSelectedDate(newSelectedDate: Date) {
    mutate((state) => {
        state.datepicker.selectedDate = newSelectedDate;
    });
}

export function setBookingData(
    updates: Partial<Pick<
        BookYourselfState['booking'],
        'firstName' | 'lastName' | 'email' | 'notes'
    >>,
) {
    mutate((state) => {
        Object.assign(state.booking, updates);
    });
}

export async function confirmBooking() {
    // TODO validate
    const state = getState();

    if (state.booking.errors.length > 0) {
        return;
    }

    // TODO start loading

    const isBooked = await bookEvent(
        state.booking.selectedOption.start,
        state.booking.selectedOption.end,
        state.booking.firstName,
        state.booking.lastName,
        state.booking.email,
        // state.booking.notes,
    );

    // TODO stop loading

    if (isBooked) {
        mutate((state) => {
            state.currentStep = BookYourselfStep.BookingConfirmed;
        });
        return;
    }

    // TODO show error popup
}

export function goToStep(step: BookYourselfStep) {
    mutate((state) => {
        state.currentStep = step;
    });
}

/* Selectors */
export function scheduleSelector(state: BookYourselfState) {
    return state.schedule;
}
export function scheduleIsLoadingSelector(state: BookYourselfState) {
    return state.schedule.isLoading;
}
export function titleSelector(state: BookYourselfState) {
    return state.schedule.title;
}
export function organizerNameSelector(state: BookYourselfState) {
    return state.schedule.organizer.name;
}
export function organizerAvatarSelector(state: BookYourselfState) {
    return state.schedule.organizer.img || null;
}
export function eventDurationSelector(state: BookYourselfState) {
    return state.schedule.eventDuration;
}
export function timezoneSelector(state: BookYourselfState) {
    return state.schedule.timeZone;
}
export function notesSelector(state: BookYourselfState) {
    return state.schedule.notes || null;
}
export function conferenceSelector(state: BookYourselfState) {
    return state.schedule.conferencingType || 0;
}
export function logoSelector(state: BookYourselfState) {
    return state.schedule.logo || undefined;
}
export function optionsIsLoadingSelector(state: BookYourselfState) {
    return state.availability.isLoading;
}
export function optionsSelector(state: BookYourselfState) {
    return state.availability.options;
}
export function shownDateSelector(state: BookYourselfState) {
    return state.datepicker.shownDate;
}
export function selectedDateSelector(state: BookYourselfState) {
    return state.datepicker.selectedDate;
}
export function selectedOptionSelector(state: BookYourselfState) {
    return state.booking.selectedOption;
}
export function firstNameSelector(state: BookYourselfState) {
    return state.booking.firstName;
}
export function lastNameSelector(state: BookYourselfState) {
    return state.booking.lastName;
}
export function emailSelector(state: BookYourselfState) {
    return state.booking.email;
}
export function addedNotesSelector(state: BookYourselfState) {
    return state.booking.notes;
}
export function currentStepSelector(state: BookYourselfState) {
    return state.currentStep;
}
