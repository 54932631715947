import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

import styles from './index.module.scss';

type NativeButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
type ButtonVariant = 'primary' | 'secondary' | 'ghost';
type ButtonSize = 'large' | 'medium' | 'small';

type ButtonProps = Omit<NativeButtonAttributes, 'type'> & {
    green?: boolean,
    red?: boolean,
    orange?: boolean,
    gray?: boolean,
    variant?: ButtonVariant,
    size?: ButtonSize,
    disabled?: boolean,
    iconLeft?: ReactNode,
    rounded?: boolean,
};

export default function Button({
    onClick,
    className,
    green,
    red,
    orange,
    gray,
    iconLeft,
    variant,
    size,
    disabled,
    rounded,
    children,
    ...buttonProps
}: ButtonProps) {
    const onlyIcon = iconLeft && !children;
    const innerStructure = variant && typeof children === 'string'
        ? <Text variant="base-primary" truncate={false}>{children}</Text>
        : children;

    return (
        <button
            type="button"
            onClick={onClick}
            className={clsx(
                styles.btn,
                {
                    [styles.green]: green,
                    [styles.red]: red,
                    [styles.orange]: orange,
                    [styles.gray]: gray,
                },
                variant && styles[`variant-${variant}`],
                size && styles[`size-${size}`],
                onlyIcon && styles.onlyIcon,
                disabled && styles.disabled,
                rounded && styles.rounded,
                className,
            )}
            disabled={disabled}
            {...buttonProps}
        >
            {iconLeft && (
                <div className={styles.btn__icon}>
                    {iconLeft}
                </div>
            )}

            {innerStructure}
        </button>
    );
};
