import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import style from './index.module.scss';

type TextVariant = 'base-primary'
| 'base-secondary'
| 'base-elementary'
| 'heading-1'
| 'heading-3'
| 'heading-4';

type TextProps = {
    variant: TextVariant,
    children: ReactNode,
    className?: string,
    truncate?: boolean,
};

export default function Text({
    variant,
    children,
    className,
    truncate = true,
}: TextProps) {
    return (
        <span
            className={
                clsx(
                    style.Text,
                    style[variant],
                    truncate && style.truncate,
                    className,
                )
            }
        >
            {children}
        </span>
    );
}
