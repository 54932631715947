import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'components/Text';
import Header from 'components/Header';
import Divider from 'components/Divider';
import Column from 'components/Column';
import Row from 'components/Row';

import { BookYourselfStep } from 'constants/schedule';

import { ReactComponent as RecmanCalendarLogo }
    from 'assets/images/recman-calendar-logo-color.svg';

import ScheduleInfo from './ScheduleInfo';
import PickDateAndTime from './PickDateAndTime';
import EnterContactDetails from './EnterContactDetails';
import BookingConfirmed from './BookingConfirmed';
import useBookYourself, {
    fetchSchedule,
    currentStepSelector,
    logoSelector,
    scheduleIsLoadingSelector,
} from './store';
import styles from './index.module.scss';

function CurrentStep() {
    const currentStep = useBookYourself(currentStepSelector);

    switch (currentStep) {
        case BookYourselfStep.EnterContactDetails:
            return <EnterContactDetails />;

        case BookYourselfStep.BookingConfirmed:
            return <BookingConfirmed />;

        case BookYourselfStep.PickDateAndTime:
        default:
            return <PickDateAndTime />;
    }
}

function HeaderWrapper() {
    const logo = useBookYourself(logoSelector);
    const isLoading = useBookYourself(scheduleIsLoadingSelector);

    return (
        <Header logo={logo} loading={isLoading} />
    );
}

export default function BookYourself() {
    const { t } = useTranslation();

    useLayoutEffect(() => {
        fetchSchedule();
    }, []);

    return (
        <Column className={styles.BookYourself}>
            <Column
                className={styles.BookYourself__wrapper}
            >
                <HeaderWrapper />

                <Row className={styles.BookYourself__body}>
                    <ScheduleInfo />

                    <Divider direction="v"/>

                    <CurrentStep />
                </Row>

                <Column className={styles.BookYourself__footer}>
                    <Text variant="base-primary">
                        {t('scheduledWithRecmanCalendar')}
                    </Text>

                    <RecmanCalendarLogo />
                </Column>
            </Column>
        </Column>
    );
}
