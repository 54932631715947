import React, { type CSSProperties } from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

type SkeletonProps = CSSProperties & {
    className?: string,
    variant?: 'rounded' | 'circle',
};

export default function Skeleton({
    className,
    variant = 'rounded',
    ...style
}: SkeletonProps) {
    return (
        <div
            className={clsx(styles.Skeleton, styles[variant], className)}
            style={style}
        />
    );
}
