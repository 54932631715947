type TimeByUnit = {
    minutes?: number,
    hours?: number,
};

/**
 * Splits minutes into object that date-fns can work with
 * Initially used to format strings, negatives are supported just in case
 * @example
 * 60 => { hours: 1 }
 * 130 => { hours: 1, minutes: 10 }
 * -100 => { hours: -1, minutes: -40 }
 */
export default function splitMinutes(minutes: number): TimeByUnit {
    if (minutes === 0 || Math.abs(minutes) < 60) {
        return { minutes };
    }

    const hours = Math.floor(minutes / 60);
    const restMinutes = minutes - (hours * 60);
    const result: TimeByUnit = {};

    result.hours = hours;

    if (Math.abs(restMinutes) > 0) {
        result.minutes = restMinutes;
    }

    return result;
}
