import React from 'react';
import { useTranslation } from 'react-i18next';
import { endOfDay, format, startOfDay } from 'date-fns';

import Text from 'components/Text';
import Button from 'components/Button';
import Column from 'components/Column';
import Row from 'components/Row';
import Datepicker from 'components/Datepicker';

import { TIME_FORMAT, type Interval } from 'constants/schedule';

import styles from '../index.module.scss';
import useBookYourself, {
    selectOption,
    setShownDate,
    setSelectedDate,
    scheduleIsLoadingSelector,
    optionsIsLoadingSelector,
    optionsSelector,
    shownDateSelector,
    selectedDateSelector,
} from '../store';

import Skeleton from './skeleton';

type TimeOptionProps = {
    option: Interval<number>,
};

function TimeOption({
    option,
}: TimeOptionProps) {
    const { t } = useTranslation();

    const timeString = format(option.start, TIME_FORMAT);

    const onClick = () => {
        selectOption(option);
    };

    return (
        <Row
            className={styles.BookYourself_PickDateAndTime_TimeOption}
        >
            <input
                type="radio"
                name="TimeOptions"
                id={timeString}
            />

            <label htmlFor={timeString}>
                <Text variant="base-primary">
                    {timeString}
                </Text>
            </label>

            <Button
                variant="primary"
                size="large"
                onClick={onClick}
            >
                <Text
                    variant="base-primary"
                    truncate={false}
                >
                    {t('next')}
                </Text>
            </Button>
        </Row>
    );
}

function TimeOptions() {
    const optionsIsLoading = useBookYourself(optionsIsLoadingSelector);
    const selectedDate = useBookYourself(selectedDateSelector);
    const options = useBookYourself(optionsSelector);

    if (optionsIsLoading || !selectedDate) {
        return null;
    }

    const selectedDateStart = startOfDay(selectedDate).getTime();
    const selectedDateEnd = endOfDay(selectedDate).getTime();
    const shownOptions = options
        .filter((option) => option.start > selectedDateStart && option.start < selectedDateEnd)
        .map((option) => (
            <TimeOption
                option={option}
                key={option.start}
            />
        ));

    return (
        <Column>
            {shownOptions}
        </Column>
    );
}

function TimeSelector() {
    const selectedDate = useBookYourself(selectedDateSelector);

    return (
        <Column className={styles.BookYourself_PickDateAndTime__time}>
            {selectedDate && (
                <>
                    <Row
                        className={
                            styles.BookYourself_PickDateAndTime__time__selectedDate
                        }
                    >
                        <Text
                            variant="heading-3"
                            className={styles.textStrong}
                        >
                            {format(selectedDate, 'EEE,')}
                        </Text>

                        <Text
                            variant="heading-3"
                            className={styles.textInfo}
                        >
                            {format(selectedDate, 'MMM d')}
                        </Text>
                    </Row>

                    <Column
                        className={
                            styles.BookYourself_PickDateAndTime__time__options
                        }
                    >
                        <TimeOptions />
                    </Column>
                </>
            )}
        </Column>
    );
}

function DateSelector() {
    const { t } = useTranslation();

    const shownDate = useBookYourself(shownDateSelector);
    const selectedDate = useBookYourself(selectedDateSelector);
    const options = useBookYourself(optionsSelector);

    const isDateDisabled = (date: Date) => {
        const dateStart = startOfDay(date).getTime();
        const dateEnd = endOfDay(date).getTime();

        return !options.some((option) => option.start > dateStart && option.start < dateEnd);
    }

    return (
        <Column className={styles.BookYourself_PickDateAndTime__date}>
            <Text
                variant="heading-3"
                className={styles.textStrong}
            >
                {t('pickADateAndTime')}
            </Text>

            <Datepicker
                shownDate={shownDate}
                setShownDate={setShownDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                isDateDisabled={isDateDisabled}
            />
        </Column>
    );
}

export default function PickDateAndTime() {
    const scheduleIsLoading = useBookYourself(scheduleIsLoadingSelector);
    const optionsIsLoading = useBookYourself(optionsIsLoadingSelector);
    const isLoading = scheduleIsLoading || optionsIsLoading;

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <>
            <DateSelector />
            <TimeSelector />
        </>
    );
}
